export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;Trading company and consultancy with experience in the trading field for more than <span style='color:#2feddd'> <b> 15 years </b> </span> with partnerships based in <span id='sp' style='color:#2feddd'> <b> Eastern Europe </b> </span> and the entire <span id='sp' style='color:#2feddd'> <b> European Economic Area. </b> </span><br><br>&nbsp;&nbsp;Our primary target is to ensure <span id='sp' style='color:#2feddd'> <b> mutual contentment </b> </span> , thus our foregoing meaningful lasting relations with our clients over the years. While human interaction and costumer service have been our strong points, we are always willing to improve our competence and services to fulfill our clients` needs."])}
  },
  "trade": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we do"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultancy"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;&nbsp;We trade internationally with <span style='color:#2feddd'><b> primary goods </b> </span> and <span style='color:#2feddd'><b>raw materials</b></span>, especially <span style='color:#2feddd'><b> dairy products</b></span>. We offer an adaptive risk policy and a flexible service.<br><br>&nbsp;&nbsp;&nbsp;Having built a <span style='color:#2feddd'><b>network</b></span> of carefully selected producers and suppliers we continue to provide the best products at the best prices."])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;&nbsp;Our experience in our industry has enabled us to offer <span style='color:#2feddd'><b> consultancy </b></span> services on important international trading matters.<br><br>&nbsp;&nbsp;&nbsp;We approach problems in an efficient manner, solving them and optimizing processes. Embracing integrity, responsibility and <span style='color:#2feddd'><b>focus on quality</b></span> has been essential in helping <span style='color:#2feddd'><b>Ardeat</b></span> become the organization it is today."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;Our priority is <span style='color:#2feddd'><b>mutual contentment</b></span>. We understand that professionalism, delivery and timing make the difference. Hence we have built up a network of loyal customers who stand as testimony to our provision of services. Our <span style='color:#2feddd'><b> long term aim </b></span> is to strive for excellence and to be the leading service on the current market."])}
  },
  "logistics": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleet"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;&nbsp;In 2020 we have established an intermediary <span style='color:#2feddd'><b>transport division</span></b>. This buy and sell division is entirely owned by our company. We have our own truck fleet that we offer for rent to our partners for delivery purposes."])},
    "buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;Our network of <span style='color:#2feddd'><b> producers, suppliers and colaborators</b></span> extend on a vast teritory over the Romanian borders. Here we offer a visual representation of a few <span style='color:#2feddd'><b>countries</b></span> we make business with."])},
    "ung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Hungary"])},
    "pol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Polland"])},
    "ger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Germany"])},
    "ola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Holland"])},
    "aus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Austria"])},
    "rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Czech Republic"])},
    "slo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Slovakia"])}
  },
  "team": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;We would describe our team as <span style='color:#2feddd'><b>highly diverse</b></span>. This is the main ingredient for our efficiency and performance. Each member has a valuable and particular perspective on things and that helps us as a team to think <span style='color:#2feddd'><b>outside the box</b></span> in order to come up with fresh ideas and creative solutions."])},
    "buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display team"])},
    "titlu2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gherasim Alecsandra Sales manager"])},
    "titlu3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andreea Doboș<br>Assistent manager"])},
    "titlu4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hegyi Diana<br>Transport manager"])},
    "titlu5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucian Mocanu<br>Transport coordinator"])}
  },
  "philantropy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philantropy"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;We strongly believe in sustainable development and social responsability as solutions for a <span style='color:#2feddd'><b>great future</b></span>. Therefore we contribute to social programs aimed to cultivate <span style='color:#2feddd'><b>the human potential</b></span> through inclusive education and personal development.<br><br>&nbsp;&nbsp;&nbsp;And if everyone took one simple step to making the world a better place each and every day, change would be <span style='color:#2feddd'><b>possible</b></span>."])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading contact"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics contact 1"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics contact 2"])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics contact 2"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "langg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
  },
  "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" <span id='fed' style='color:#2feddd;'> Always</span>&nbsp;reliable."])},
  "citat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'<span style='color:#6EDFF6'><i>We have a vision to continue in operating at the highest level, whilst acting as a solid pillar for our partners to grow their businesses and service their customers in a way that is world-class</i>'</span>.<br><br><span style='color:#66FFA3'><b>Vasile (Ardeat CEO)</b></span>"])},
  "langg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
}