import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
   path: '/trading',
   name: 'trading',
   component: () => import(/* webpackChunkName: "about" */ '../views/Trading.vue')
  },
  {
  path: '/logistics',
  name:'logistics',
  component: () => import(/* webpackChunkName: "about" */ '../views/Logistics.vue')
  },
  {
    path:'/team',
    name:'team',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team.vue')
  },
  {
   path:'/philantropy',
   name:'philantropy',
   component: () => import(/* webpackChunkName: "about" */ '../views/Philantropy.vue')
  },
  {
    path:'/contact',
    name:'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
