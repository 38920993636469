<template>
<div id="app">
  <img id="img" src="front.webp" />  
  <img id="img1" src="mountainpic.webp" /> 
  <img id="ardeat3" src="ardeat.webp" />
  <text v-if="!show" id="text" style="font-weight:lighter" v-html="$t('home.text')"></text>
  <text v-if="!show" id="text11" v-html="$t('home.text')"></text>
 <h3 v-if="!show" id="h3" style="font-weight:lighter"> {{ $t('home.subtitle') }} </h3>
 <h3 v-if="!show" id="h31" style="font-weight:lighter"> {{ $t('home.subtitle') }} </h3>
 <h1 id="h11" v-if="!show" style="color:white;font-weight:lighter"> {{ $t('home.title') }} </h1>
 <h1 id="h12" v-if="!show" style="color:white;font-weight:lighter"> {{ $t('home.title') }} </h1>
    </div>
    
 
</template>
<script>
</script>

<style scoped>
#h11 {
  position:absolute;
  left:2.5%;
  width:8em;
  top:11.6%;
  font-size:30px;
background-color:#0D6EFD;
border-radius:5px;
border:transparent;
color:white;
font-family:inherit;
padding:5px;
display:none;
}
#h12 {
  position:absolute;
        left:0;
  top:0;
  width:8em;
  margin-top:3.6em;
  margin-left:1em;
  font-size:32px;
background-color:#0D6EFD;
border-radius:5px;
border:transparent;
color:white;
font-family:inherit;
padding:5px;

}
#text {
  position:absolute;
  left:27%;
  top:25%;
  font-size:14px;
  width:26em;
  opacity:0.9;
  border-radius:1em;
  color:white;
  background-color:#3E4751;
  outline:.6px solid white;
  padding:10px 30px 25px;
  text-align:initial;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display:none;
}
#text11 {
  position:absolute;
  left:27%;
  top:25%;
  font-size:12px;
  width:26em;
  
  border-radius:1em;
  color:white;
  background-color:#3E4751;
  padding:10px 30px 25px;
  text-align:initial;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
 
}
#h3 {
  position:absolute;  
  left:36%;
  top:22%;
  font-size:18px;
  width:8em;
  opacity:0.9;
  border-radius:5px;
  color:white;
  border:transparent;
  background-color:#0D6EFD;
  outline:.6px solid #0D6EFD;
  padding:3px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  display:none;
}
#h31 {
  position:absolute;  
  left:35%;
  top:22%;
  font-size:15px;
  width:8em;
  font-weight:500;
  border-radius:5px;
  color:white;
  background-color:#0D6EFD;
  border:.6px solid #0D6EFD;
  padding:3px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

#text1 {
  font-size:14px;
  position:absolute;
  top:20.2em;
  left:23em;
  background:transparent;
  color:rgb(223, 221, 221); 
  padding:20px;
  width:30em;
  height:22em;
  font-weight:lighter;
  font-family:inherit;
  opacity:1;
}
#img1 {
 display:none;
 position:absolute;
 top:0em;
 width:100%;
 top:0px;
left:0px;
height:100%;
right:0px;
bottom:0px;
 filter:grayscale(80%);
}
html {
background: url('https://i.ibb.co/KL8KGHX/cac67314647685-55d73ccb56ce5.jpg') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}


#img {
  position:absolute;
  width:100%;
  height:100%;
left:0px;
top:0px;
  filter:grayscale(100%);
  
}

#ardeat3 {
POSITION:absolute;
height:46px;
width:191px;
left:35em;
display:none;
z-index:99 !important;
top:10em;
}
.fa-solid {
  font-size:23px;
  position:absolute;
  top:-16.2em;
  left:20.6em;
  z-index:99;
}
@media (min-width:300px) and (max-width:499px) {
#front {
  display:none;
}
#img {
  display:none;
}
#img1 {
  display:block;
}

#h11 {
  font-size:16px;
  top:15%;
  left:37.1%;
  display:none;
  }
  #h12{
    display:none;
  }
  #text {
   
    top:30%;
    left:38%;
     font-size:8px;
     outline:none;
     width:17em;
     background-color:transparent;
     opacity:1;
     box-shadow:none;
     padding:2px 10px 12px 20px;
     display:block;
  }
  #text11 {
    display:none;
  }
   #h3 {
    font-size:9px;
    left:50%;
    top:27%;
    opacity:1;
    display:block;
   }
   #h31 {
    display:none;
   }
   

}
@media (min-height:300px) and (max-height:349px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:4.7em;
    margin-top:4em;
    font-size:6px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:2.8em;
    margin-top:6em;
    font-size:7px;
  }
}
@media (min-height:300px) and (max-height:349px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:6.6em;
    margin-top:4em;
    font-size:6px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:5em;
    margin-top:6em;
    font-size:7px;
  }
}
@media (min-height:300px) and (max-height:349px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:7.2em;
    margin-top:3.1em;
    font-size:7px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:6em;
    margin-top:4em;
    font-size:8px;
  }
}
@media (min-height:300px) and (max-height:349px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:7.2em;
    margin-top:3.1em;
    font-size:7px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:6em;
    margin-top:4em;
    font-size:8px;
  }
}
@media (min-height:350px) and (max-height:399px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:4.7em;
    margin-top:4em;
    font-size:6px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:1.5em;
    margin-top:6em;
    font-size:8px;
  }
}
@media (min-height:350px) and (max-height:399px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:4.3em;
    margin-top:3em;
    font-size:8px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:4em;
    margin-top:5em;
    font-size:8px;
  }
}
@media (min-height:350px) and (max-height:399px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:4.6em;
    margin-top:3em;
    font-size:8.5px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:4.5em;
    margin-top:5em;
    font-size:8.5px;
  }
}
@media (min-height:350px) and (max-height:399px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:12em;
    margin-top:11.4em;
    font-size:9px;
  }
  #h3 {
    top:0;
    right:0;
    margin-left:5.3em;
    margin-top:2.1em;
    font-size:10px;
  }
  .lds-ellipsis {
    display:none;
  }
  #text {
    top:0;
    right:0;
    margin-left:4.5em;
    margin-top:4em;
    font-size:10px;
  }
}
@media (min-height:400px) and (max-height:449px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9.5em;
    margin-top:7.8em;
    font-size:13px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:1.5em;
    margin-top:10em;
    font-size:8px;
  }
  #text {
    right:0;
    top:0;
    margin-left:0.4em;
    margin-top:10.5em;
    font-size:8.5px;
  }
  
}
@media (min-height:400px) and (max-height:449px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9.5em;
    margin-top:7.8em;
    font-size:13px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:2.5em;
    margin-top:9.3em;
    font-size:9px;
  }
  #text {
    right:0;
    top:0;
    margin-left:0.4em;
    margin-top:10.5em;
    font-size:9px;
  }
  
}
@media (min-height:400px) and (max-height:449px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9.5em;
    margin-top:7.8em;
    font-size:13px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:2.5em;
    margin-top:9em;
    font-size:9px;
  }
  #text {
    right:0;
    top:0;
    margin-left:2em;
    margin-top:10em;
    font-size:9px;
  }
  
}
@media (min-height:400px) and (max-height:449px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:11em;
    margin-top:6.6em;
    font-size:16.5px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:3.5em;
    margin-top:9.2em;
    font-size:10px;
  }
  #text {
    right:0;
    top:0;
    width:20em;
    margin-left:1em;
    margin-top:10.5em;
    font-size:10px;
  }
  
}


@media (min-height:450px) and (max-height:499px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.6em;
    font-size:16px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:1em;
    margin-top:8.4em;
    font-size:10px;
  }
  #text {
    right:0;
    top:0;
    margin-left:-1.5em;
    margin-top:10.5em;
    font-size:9px;
  }


}
@media (min-height:450px) and (max-height:499px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:6.6em;
    font-size:16.5px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0em;
    margin-top:8em;
    font-size:11px;
  }
  #text {
    left:0;
    top:0;
    margin-left:12.2em;
    margin-top:10.4em;
    font-size:10px;
  }


}
@media (min-height:450px) and (max-height:499px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9.5em;
    margin-top:7.8em;
    font-size:13px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0.5em;
    margin-top:8.7em;
    font-size:10px;
  }
  #text {
    right:0;
    top:0;
    margin-left:0.3em;
    margin-top:9.3em;
    font-size:10px;
  }


}
@media (min-height:450px) and (max-height:499px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:5.8em;
    font-size:19px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0em;
    margin-top:9.4em;
    font-size:10px;
  }
  #text {
    right:0;
    top:0;
    width:17.4em;
    margin-left:-0.8em;
    margin-top:10.6em;
    font-size:10px;
  }


}
@media (min-height:500px) and (max-height:549px) and (min-width:300px) and (max-width:349px) {

    #h11 {
      left:0;
    top:0;
    margin-left:8em;
    margin-top:6.6em;
    font-size:16px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0em;
    margin-top:9.5em;
    font-size:9px;
  }
  #privacy {
    font-size:14px;
  }
  #text {
    right:0;
    top:0;
    width:18em;
    margin-left:-2em;
    margin-top:11em;
    font-size:9px;
  }
  }
  @media (min-height:500px) and (max-height:549px) and (min-width:350px) and (max-width:399px) {

#h11 {
  left:0;
top:0;
margin-left:8em;
margin-top:6.3em;
font-size:17px;
}
#h3 {
right:0;
top:0;
margin-left:0.4em;
margin-top:10em;
font-size:9.3px;
}
#privacy {
font-size:14px;
}
#text {
right:0;
top:0;
width:16.7em;
margin-left:-1em;
margin-top:10em;
font-size:10.3px;
}
}
@media (min-height:500px) and (max-height:549px) and (min-width:400px) and (max-width:449px) {

#h11 {
  left:0;
top:0;
margin-left:8em;
margin-top:6em;
font-size:18.4px;
}
#h3 {
right:0;
top:0;
margin-left:0em;
margin-top:9.2em;
font-size:10.5px;
}
#privacy {
font-size:14px;
}
#text {
right:0;
top:0;
width:16.3em;
margin-left:-0.5em;
margin-top:9.1em;
font-size:11px;
}
}
@media (min-height:500px) and (max-height:549px) and (min-width:450px) and (max-width:499px) {

#h11 {
  left:0;
top:0;
margin-left:10em;
margin-top:6em;
font-size:18.4px;
}
#h3 {
right:0;
top:0;
margin-top:9.7em;
font-size:10.5px;
}
#privacy {
font-size:14px;
}
#text {
right:0;
top:0;
width:17.7em;
margin-left:-0.2em;
margin-top:10.4em;
font-size:10.5px;
}
}
  @media (min-height:550px) and (max-height:599px) {
    #h11 {
      left:0;
    top:0;
    margin-left:8em;
    margin-top:6.6em;
    font-size:16px;
  }
  #h3 {
    left:0;
    top:0;
    margin-left:20em;
    margin-top:20em;
    font-size:8px;
  }
  #privacy {
    font-size:14px;
  }
  #text {
    left:0;
    top:0;
    margin-left:17em;
    margin-top:24.5em;
    font-size:7px;
  }
  }
  @media (min-height:550px) and (max-height:599px) and (min-width:300px) and (max-width:349px) {
    #h11 {
      left:0;
    top:0;
    margin-left:7.5em;
    margin-top:6em;
    font-size:17px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:12.5em;
    margin-top:8.4em;
    font-size:11px;
  }
  #privacy {
    font-size:14px;
  }
  #text {
    left:0;
    top:0;
    margin-left:9.3em;
    margin-top:10em;
    font-size:10px;
  }
  }
  @media (min-height:550px) and (max-height:599px) and (min-width:350px) and (max-width:399px) {
    #h11 {
      left:0;
    top:0;
    margin-left:7.5em;
    margin-top:5.9em;
    font-size:18px;
  }
  #h3 {
    left:0;
    top:0;
    margin-left:17em;
    margin-top:9.2em;
    font-size:10px;
  }
  #privacy {
    font-size:14px;
  }
  #text {
    right:0;
    top:0;
    width:16.4em;
    margin-left:9.5em;
    margin-top:8em;
    font-size:11.5px;
  }
  }
  @media (min-height:550px) and (max-height:599px) and (min-width:400px) and (max-width:449px) {
    #h11 {
      left:0;
    top:0;
    margin-left:7.5em;
    margin-top:6em;
    font-size:19px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:18em;
    margin-top:9.9em;
    font-size:10.5px;
  }
  #privacy {
    font-size:14px;
  }
  #text {
    right:0;
    top:0;
    width:17.3em;
    margin-left:10em;
    margin-top:9em;
    font-size:12px;
  }
  }
  @media (min-height:550px) and (max-height:599px) and (min-width:450px) and (max-width:499px) {
    #h11 {
      left:0;
    top:0;
    margin-left:9em;
    margin-top:5.8em;
    font-size:20px;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:20em;
    margin-top:9.5em;
    font-size:11px;
  }
  #privacy {
    font-size:14px;
  }
  #text {
    right:0;
    top:0;
    width:16.3em;
    margin-left:15.7em;
    margin-top:10.8em;
    font-size:11px;
  }
  }
  @media (min-height:600px) and (max-height:649px) and (min-width:300px) and (max-width:349px) {
    #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:10.5px;
    right:0;
    top:0;
    width:16em;
    margin-left:-1.8em;
    margin-top:10em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:8.5em;
    font-size:10.5px;
  }
  }
  @media (min-height:600px) and (max-height:649px) and (min-width:350px) and (max-width:399px) {
    #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:11px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2em;
    margin-top:9.5em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:8.8em;
    font-size:11px;
    margin-left:-1em;
  }
  }
  @media (min-height:600px) and (max-height:649px) and (min-width:400px) and (max-width:449px) {
    #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:6.8em;
    font-size:17px;
  }
  #text {
    font-size:12px;
    right:0;
    top:0;
    margin-left:-1em;
    width:16.3em;
    margin-top:10em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0em;
    margin-top:10em;
    font-size:10.5px;
  }
  }
  @media (min-height:600px) and (max-height:649px) and (min-width:450px) and (max-width:499px) {
    #h11 {
    left:0;
    top:0;
    margin-left:11em;
    margin-top:6.2em;
    font-size:18px;
  }
  #text {
    font-size:13.5px;
    right:0;
    top:0;
    width:16.3em;
    margin-top:9.4em;
    margin-left:-1em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:10em;
    font-size:12px;
  }
  }
  @media (min-height:650px) and (max-height:699px) and (min-width:300px) and (max-width:349px) {
    #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:10.5px;
    right:0;
    top:0;
    width:16em;
    margin-left:-1.8em;
    margin-top:10em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:8.5em;
    font-size:10.5px;
  }
      
  }
  @media (min-height:650px) and (max-height:699px) and (min-width:350px) and (max-width:399px) {
    #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:11px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2em;
    margin-top:9.5em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:8.8em;
    font-size:11px;
    margin-left:-1em;
  }
      
  }
  @media (min-height:650px) and (max-height:699px) and (min-width:400px) and (max-width:449px) {
    #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:6.8em;
    font-size:17px;
  }
  #text {
    font-size:13px;
    right:0;
    top:0;
    margin-left:-1em;
    width:16.3em;
    margin-top:8.5em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0em;
    margin-top:10em;
    font-size:10.5px;
  }
      
  }
  @media (min-height:650px) and (max-height:699px) and (min-width:450px) and (max-width:499px) {
    #h11 {
    left:0;
    top:0;
    margin-left:11em;
    margin-top:6.2em;
    font-size:18px;
  }
  #text {
    font-size:13.5px;
    right:0;
    top:0;
    width:16.3em;
    margin-top:9.4em;
    margin-left:-1em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:10em;
    font-size:12px;
  }
      
  }
@media (min-height:700px) and (max-height:749px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:10.5px;
    right:0;
    top:0;
    width:16em;
    margin-left:-2em;
    margin-top:10em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:9.2em;
    font-size:10.5px;
  }

}
@media (min-height:700px) and (max-height:749px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:11px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2em;
    margin-top:10.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.7em;
    font-size:11px;
    margin-left:-1em;
  }

}
@media (min-height:700px) and (max-height:749px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:6.8em;
    font-size:17px;
    display:none;
  }
  #text {
    font-size:13.5px;
    right:0;
    top:0;
    margin-left:-1.5em;
    width:16.3em;
    margin-top:8.5em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:0em;
    margin-top:10em;
    font-size:10.5px;
  }

}
@media (min-height:700px) and (max-height:749px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:11em;
    margin-top:6.2em;
    font-size:18px;
  }
  #text {
    font-size:14.5px;
    right:0;
    top:0;
    width:16.3em;
    margin-top:8.8em;
    margin-left:-1em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.2em;
    font-size:13px;
  }

}
@media (min-height:750px) and (max-height:799px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
    display:none;
  }
  #text {
    font-size:11.5px;
    right:0;
    top:0;
    width:16em;
    margin-left:-2.5em;
    margin-top:9em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:9em;
    font-size:10.5px;
  }

}
@media (min-height:750px) and (max-height:799px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:13px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2.5em;
    margin-top:8.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.7em;
    font-size:11px;
    margin-left:-1em;
  }


}
@media (min-height:750px) and (max-height:799px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:6.8em;
    font-size:17px;
  }
  #text {
    font-size:14px;
    right:0;
    top:0;
    margin-left:-2em;
    width:16.3em;
    margin-top:8.5em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.7em;
    margin-top:8.8em;
    font-size:13px;
  }


}
@media (min-height:750px) and (max-height:799px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:11em;
    margin-top:6.2em;
    font-size:18px;
  }
  #text {
    font-size:15px;
    right:0;
    top:0;
    width:15.3em;
    margin-top:9.5em;
    margin-left:-2em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-1em;
    margin-top:10em;
    font-size:13px;
  }


}
@media (min-height:800px) and (max-height:850px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
    display:none;
  }
  #text {
    font-size:11.5px;
    right:0;
    top:0;
    width:14em;
    margin-left:-1.4em;
    margin-top:10.2em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:10.2em;
    font-size:10.5px;
  }
}
@media (min-height:800px) and (max-height:850px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:13px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2.5em;
    margin-top:8.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.7em;
    font-size:11px;
    margin-left:-1em;
  }
}
@media (min-height:800px) and (max-height:850px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:9em;
    margin-top:6.8em;
    font-size:17px;
  }
  #text {
    font-size:14px;
    right:0;
    top:0;
    margin-left:-2em;
    width:16.3em;
    margin-top:8.5em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.7em;
    margin-top:8.8em;
    font-size:13px;
  }
}
@media (min-height:800px) and (max-height:850px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:11em;
    margin-top:6.2em;
    font-size:18px;
  }
  #text {
    font-size:15px;
    right:0;
    top:0;
    width:15.3em;
    margin-top:9.1em;
    margin-left:-2em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-1em;
    margin-top:10em;
    font-size:13px;
  }
}
@media (min-height:850px) and (max-height:899px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:11.5px;
    right:0;
    top:0;
    width:14em;
    margin-left:-1.4em;
    margin-top:10.2em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:10.2em;
    font-size:10.5px;
  }
  
}
@media (min-height:850px) and (max-height:899px) and (min-width:350px) and (max-width:300px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:13px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2.5em;
    margin-top:8.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.7em;
    font-size:11px;
    margin-left:-1em;
  }
}
@media (min-height:850px) and (max-height:899px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:14px;
    right:0;
    top:0;
    width:15.5em;
    margin-left:-1.9em;
    margin-top:8.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:8.7em;
    font-size:13px;
    margin-left:-1em;
  }
}
@media (min-height:850px) and (max-height:899px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:15.5px;
    right:0;
    top:0;
    width:15.5em;
    margin-left:-1.9em;
    margin-top:9.9em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.1em;
    font-size:15px;
    margin-left:-1em;
  }
}
@media (min-height:900px) and (max-height:949px) and (min-width:300px) and (max-width:349px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:11.5px;
    right:0;
    top:0;
    width:14em;
    margin-left:-1.4em;
    margin-top:10.2em;
  }
  #h3 {
    right:0;
    top:0;
    margin-left:-0.5em;
    margin-top:10.2em;
    font-size:10.5px;
  }
}
@media (min-height:900px) and (max-height:949px) and (min-width:350px) and (max-width:399px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
    display:none;
  }
  #text {
    font-size:13px;
    right:0;
    top:0;
    width:16.5em;
    margin-left:-2.5em;
    margin-top:8.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.7em;
    font-size:11px;
    margin-left:-1em;
  }
}
@media (min-height:900px) and (max-height:949px) and (min-width:400px) and (max-width:449px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
    display:none;
  }
  #text {
    font-size:14px;
    right:0;
    top:0;
    width:15.5em;
    margin-left:-1.9em;
    margin-top:8.7em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:8.7em;
    font-size:13px;
    margin-left:-1em;
  }
}
@media (min-height:900px) and (max-height:949px) and (min-width:450px) and (max-width:499px) {
  #h11 {
    left:0;
    top:0;
    margin-left:8em;
    margin-top:6.8em;
    font-size:16px;
  }
  #text {
    font-size:15.5px;
    right:0;
    top:0;
    width:15.5em;
    margin-left:-1.9em;
    margin-top:9.9em;
  }
  #h3 {
    right:0;
    top:0;
    margin-top:9.1em;
    font-size:15px;
    margin-left:-1em;
  }
}
@media (min-width:500px) and (max-width:599px) {
  #h12 {
  font-size:9px;
  left:0;
  top:0;
  margin-top:4.5em;
  margin-left:2em;
  }
  #text11 {
    font-size:4px;
    left:0;
    top:0;
    margin-left:33em;
    margin-top:20em;
    padding:7px;
   }
   #h31 {
    font-size:4.5px;
    left:0;
    top:0;
    margin-left:38.5em;
    margin-top:16.2em;
    padding:2px;
   }
   
}
@media (min-width:600px) and (max-width:699px) {
  #h12 {
  font-size:13.5px;
  left:0;
  top:0;
  margin-left:1em;
  margin-top:3.9em;
  }
  #text11 {
    font-size:5px;
    left:0;
  top:0;
  margin-left:32em;
  margin-top:20.6em;
  padding:15px;
  padding-top:5px;
   }

   #h31 {
    font-size:6px;
    left:0;
    top:0;
    margin-left:35.5em;
    margin-top:16em;
   }
}

@media (min-width:700px) and (max-width:799px) {
  #h12 {
  font-size:17px;
  left:0;
  top:0;
  margin-left:1.1em;
  margin-top:3.9em;
} 
#text11 {
  font-size:6px;
  left:0;
  top:0;
  margin-left:32em;
  margin-top:20.6em;
  padding:15px;
  padding-top:5px;
   }
   #h31 {
    font-size:8px;
    left:0;
    top:0;
    margin-left:31.5em;
    margin-top:14.5em;
   }
}

@media (min-width:800px) and (max-width:899px) {
 #h12 {
  font-size:18.5px;
  left:0;
  top:0;
  margin-left:0.6em;
  margin-top:3.7em;
 }  
 #text11 {
  font-size:8px;
  left:0;
  top:0;
  margin-left:26em;
  margin-top:21em;
  padding:15px;
  padding-top:5px;
 }
 #h31 {
  font-size:9px;
  left:0;
  top:0;
  margin-left:32.2em;
  margin-top:18em;
 }

}



@media (min-width:1300px) and (max-width:1399px) {
#text11 {
   left:0;
   top:0;
   margin-left: 30em;
margin-top: 15em;
}
#h31 {
  font-size:17px;
  left:0;
  top:0;
  margin-left:28em;
  margin-top:10em;
  
  
}
}
@media (min-width:1600px) and (max-width:1699px) {
  #text11 {
   font-size:14px;
   left:0;
   top:0;
   margin-left:30em;
   margin-top:17em;
  }
  #h31 {
font-size:18px;
left:0;
top:0;
margin-left:31.4em;
margin-top:12.2em;
  }
}
@media (min-width:1700px) and (max-width:1799px) {
  #text11 {
   font-size:14px;
   left:0;
   top:0;
   margin-left:30em;
   margin-top:17em;
  }
  #h31 {
font-size:18px;
left:0;
top:0;
margin-left:31.4em;
margin-top:12.2em;
  }
}
@media (min-width:1800px) and (max-width:1899px) {
  #text11 {
   font-size:14px;
   left:0;
   top:0;
   margin-left:30em;
   margin-top:17em;
  }
  #h31 {
font-size:18px;
left:0;
top:0;
margin-left:31.4em;
margin-top:12.2em;
  }
}
@media (min-width:1900px) and (max-width:1999px) {
  #h31 {
    left:0;
    top:0;
    margin-left:43em;
    margin-top:17em;
  }
  #h12 {
    left:0;
    top:0;
    margin-left:1em;
    margin-top:4em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:42.5em;
    margin-top:22em;
  }
}


@media (min-width:900px) and (max-width:999px) {
#h12 {
  font-size:22px;
  top:0;
  left:0;
  margin-left:1em;
  margin-top:3.7em;
}  
#text11 {
  font-size:8px;
  left:0;
  top:0;
  margin-left:30em;
  margin-top:17em;
}
#h31 {
  font-size:10px;
  left:0;
  top:0;
  margin-left:33em;
  margin-top:12.5em;
}
}
@media (min-width:1000px) and (max-width:1099px) {
  #h12 {
    font-size:22px;
    left:0;
    top:0;
    margin-left:1.1em;
    margin-top:3.9em;
  }
  #text11 {
    font-size:9px;
  }
  #h31 {
    font-size:12px;
    left:36.3%;
    top:21%;
  }
  
  #terms {

  }

}
@media (min-width:1100px) and (max-width:1199px) {
  #h12 {
  font-size:25px;
left:0;
top:0;
margin-left:1.4em;
margin-top:3.9em;
  }
  #text11 {
    font-size:9px;
    left:0;
    top:0;
    margin-left:30em;
    margin-top:20em;
  }
  #h31 {
    font-size:12px;
    left:0;
    top:0;
    margin-left:30em;
    margin-top:14em;
  }
  
}
@media (min-width:1200px) and (max-width:1299px) {
  #h12 {
    font-size:26px;
left:0;
top:0;
margin-left:1.4em;
margin-top:3.9em;
  }
  #h31 {
    font-size:16px;
    left:0;
    top:0;
    margin-left:27.5em;
    margin-top:9.7em;
  }
  #text11 {
    font-size:12px;
    left:0;
    top:0;
    margin-left:27em;
    margin-top:14.7em;
  }
  
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@media (min-width:1400px) and (max-width:1499px) {
  #h31 {
    margin-left:1em;
    margin-top:0.2em;
  }
}
@media (min-width:2000px) and (max-width:2099px) {
  #h31 {
    margin-left:-2em;
  }
}
@media (min-width:2100px) and (max-width:2199px) {
  #h31 {
    margin-left:-3em;
  }
}
  @media (min-width:2200px) and (max-width:2299px) {
    #h12 {
      left:0;
      top:0;
      margin-left:1em;
      margin-top:3.6em;
    }
    #h31 {
      left:0;
      top:0;
      margin-top:11em;
      margin-left:47em;
    }
    #text11 {
      left:0;
      top:0;
      margin-left:48em;
      margin-top:14.4em;
    }
  } 
@media (min-width:2300px) and (max-width:2399px) {
  #h31 {
    left:0;
    top:0;
    margin-top:15em;
    margin-left:33em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:30.4em;
    margin-top:20em;
  }
}
@media (min-width:2400px) and (max-width:2499px) {
  #h31 {
    left:0;
    top:0;
    margin-top:15em;
    margin-left:33em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:30.4em;
    margin-top:20em;
  }
}
@media (min-width:2500px) and (max-width:2599px) {
  #h31 {
    left:0;
    top:0;
    margin-left:53em;
    margin-top:14em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:56em;
    margin-top:19.5em;
  }
}
@media (min-width:2600px) and (max-width:2699px) {
  #h31 {
    left:0;
    top:0;
    margin-left:54em;
    margin-top:13.5em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:56em;
    margin-top:19.3em;
  }
}
@media (min-width:2700px) and (max-width:2799px) {
  #h31 {
    left:0;
    top:0;
    margin-top:13.5em;
    margin-left:58em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:62em;
    margin-top:19.3em;
  }
}
@media (min-width:2800px) and (max-width:2899px) {
  #h31 {
    left:0;
    top:0;
    margin-top:13.5em;
    margin-left:57em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:60em;
    margin-top:19.3em;
  }
}
@media (min-width:2900px) and (max-width:2999px) {
  #h31 {
    left:0;
    top:0;
    margin-left:60em;
    margin-top:14.5em;
  }
  #text11 {
    left:0;
    top:0;
    margin-left:60em;
    margin-top:19.3em;
  }
}
@media (min-width:3000px) and (max-width:3099px) {
 #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}

@media (min-width:3100px) and (max-width:3199px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3200px) and (max-width:3299px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3300px) and (max-width:3399px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3400px) and (max-width:3499px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3500px) and (max-width:3599px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3600px) and (max-width:3699px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3700px) and (max-width:3899px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:3900px) and (max-width:3999px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4000px) and (max-width:4099px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4100px) and (max-width:4199px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4200px) and (max-width:4299px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4300px) and (max-width:4399px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4400px) and (max-width:4499px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4500px) and (max-width:4599px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4600px) and (max-width:4799px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4700px) and (max-width:4799px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4800px) and (max-width:4899px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:4900px) and (max-width:4999px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
  }
}
@media (min-width:5000px) and (max-width:5099px) {
  #h31 {
  top:0;
  left:0;
  margin-left:63em;
  margin-top:27em;
 }
 #text11 {
    left:0;
    top:0;
    margin-left:67em;
    margin-top:34.3em;
 }
}
@media (min-width:5400px) and (max-width:6100px) {
  #h31 {
   margin-left:-22em;
   margin-top:5em;
  }
  #text11 {

  }
}


</style>


