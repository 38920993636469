export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compania"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cine suntem"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;Societate comercială și de consultanță cu experiență în vânzări de peste <span style='color:#2feddd'><b> 10 ani </b> </span> , cu parteneriate în <span style='color:#2feddd'><b> Europa de est </b> </span> și în întregul <span style='color:#2feddd'> <b> areal economic european</b></span>. <br><br>&nbsp;&nbsp;Obiectivul nostru principal este să asigurăm <span style='color:#2feddd'><b> satisfacția </b></span> clienților noștri. Prin acest lucru am reușit să menținem relații de lungă durată cu ei de-a lungul anilor. Din acest motiv cautăm tot timpul să îmbunătățim felul în care lucrăm și calitatea serviciilor pentru a ne ridica la standerdele cerute și la cele pe care noi înșine ni le-am propus."])}
  },
  "trade": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vânzări"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicii"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultanță"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;Noi comercializăm <span style='color:#2feddd'><b> materii prime</b></span>, in special <span style='color:#2feddd'><b> lactate</b></span>. Lucrăm cu o politică de risc adaptativă și servicii flexibile.<br><br> &nbsp;&nbsp;Avem acces la o <span style='color:#2feddd'><b> rețea </b></span> importantă de producători și furnizori aleși cu grijă de noi în scopul de a oferii cele mai bune produse la cele mai bune prețuri."])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;Experiența noastră din domeniul vânzări ne-a ajutat să oferim servicii de<span style='color:#2feddd'><b> consultanță</b></span> în materie de trading internațional. <br><br>&nbsp;&nbsp;Noi abordăm probleme intr-o manieră eficientă, rezolvându-le si optimizând procesele. Cu valori ca integritate și atenție sporită pe <span style='color:#2feddd'><b> calitate </b></span> am reușit sa clădim <span style='color:#2feddd'><b> Ardeat </b></span> în forma în care o regăsiți astăzi."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" <br><br>&nbsp;&nbsp;Prioritatea noastră este de a <span style='color:#2feddd'><b>satisface ambele părți</b></span> și înțelegem că profesionalismul, livrarea și sincronizarea fac diferența. Astfel noi am obținut respectul clienților noștrii de-a lungul timpului, iar aceștia continuă să lucreze cu noi cu plăcere. Cu toate acestea este importantă<span style='color:#2feddd'><b> viziunea noastră</b> </span> de a rămâne printre traderii de top din segmentul nostru de activitate."])}
  },
  "logistics": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistică"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flotă"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;În 2020 am înființat o <span style='color:#2feddd'><b>divizie de transport</b></span> și intermediere. Această divizie de tip vând-cumpăr este în deplinatăte deținută de compania noastră. Avem propria noastră flotă de camioane pe care o oferim spre închiriere partenerilor noștri."])},
    "buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hartă"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;Rețeaua noastră de <span style='color:#2feddd'><b> producatori, furnizori și colaboratori </b></span> se extinde pe un areal vast dincolo de granițele României. Aici vă oferim o reprezentare vizuală a unor <span style='color:#2feddd'><b>țări</b></span> cu care colaborăm."])},
    "ung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ungaria"])},
    "pol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Polonia"])},
    "ger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Germania"])},
    "ola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Olanda"])},
    "aus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Austria"])},
    "rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Republica Cehă"])},
    "slo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Slovacia"])}
  },
  "team": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echipa"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispunere"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br>&nbsp;&nbsp;O descriere foarte potrivită a echipei noastre ar fi : <span style='color:#2feddd'><b>foarte diversificată</b></span>. Acesta este punctul forte pentru eficiență și performanță. Fiecare membru are o trasatură specifică, iar asta ne ajută ca și echipă sa avem <span style='color:#2feddd'><b>mintea deschisă</b></span> pentru a veni cu soluții creative."])},
    "buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arată echipa"])},
    "titlu2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gherasim Alecsandra<br>Director vânzări"])},
    "titlu3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andreea Doboș<br>Asistent manager"])},
    "titlu4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hegyi Diana<br>Manager transport"])},
    "titlu5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mocanu Lucian<br>Coordonator transport"])}
  },
  "philantropy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filantropie"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezvoltare"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Credem puternic într-o dezvoltare susținută și în responsabilitate socială ca și soluții pentru un <span style='color:#2feddd'><b>viitor strălucit</b></span>. Din acest motiv contribuim la programe sociale care au ca și target <span style='color:#2feddd'><b>potențialul uman</b></span> prin educație și dezvoltare personală. <br><br>&nbsp;&nbsp;Dacă toată lumea ar face un pas mic în fiecare zi pentru a face lumea mai bună, atunci s-ar vedea <span style='color:#2feddd'><b>diferența</span></b>."])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locație"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactul de trading"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactul de logistică 1"])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactul de logistică 2"])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactul de logistică 2"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
    "buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hartă"])}
  },
  "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='position:absolute;left:-2em;width:12em;'><span style='color:#2feddd'> Întodeauna </span> de încredere.<span>"])},
  "citat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br><br><span style='color:#2feddd'><i>'Noi continuăm sa operăm la cel mai înalt nivel,fiind in același timp stâlp pentru partenerii noștrii in așa fel incât activitățile lor comerciale să prospere și să-și mulțumească clienții intr-o manieră cât mai calitativă.'</i></span><br><br><span style='color:#2feddd'><b>Vasile (Ardeat CEO)</b></span>"])},
  "langg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])}
}